@import "reset.css";
@import "../fonts/source-sans-pro/stylesheet.css";
@import "../fonts/calibre/stylesheet.css";
@import "../fonts/courier-prime/stylesheet.css";

@import "typography.css";
@import "form.css";
@import "animations.css";

:root {
  --color-indigo-d: hsl(234, 90%, 25%);
  --color-indigo-md: hsl(230, 80%, 38%);
  --color-indigo-tint: hsl(216, 100%, 93%);

  --color-yellow-d: hsl(15, 86%, 30%);
  --color-yellow-md: hsl(22, 82%, 39%);
  --color-yellow-m: hsl(42, 87%, 55%);
  --color-yellow-tint: hsl(48, 100%, 88%);

  --color-teal-d: hsl(170, 97%, 15%);
  --color-teal-m: hsl(162, 63%, 41%);
  --color-teal-tint: hsl(152, 68%, 96%);

  --color-purple-d: hsl(308, 91%, 23%);
  --color-purple-md: hsl(306, 90%, 31%);
  --color-purple-tint: hsl(262, 90%, 96%);

  --color-red-d: hsl(350, 94%, 28%);
  --color-red-tint: hsl(360, 100%, 95%);

  --color-gray-m: hsl(211, 12%, 43%);
  --color-gray-ml: hsl(211, 13%, 65%);
  --color-gray-l: hsl(210, 16%, 82%);
  --color-gray-tint: hsl(216, 33%, 97%);

  --link-color: var(--color-yellow-d);
  --button-color: var(--color-red-d);
  --button-hover-color: var(--color-yellow-md);

  --color-text: hsl(209, 61%, 16%);
  --color-background: white;

  --space-x-small: 0.25em;
  --space-small: 0.5em;
  --space-medium: 1em;
  --space-large: 2em;
  --space-x-large: 3em;
  --space-xx-large: 4em;
  --space-xxx-large: 6em;
  --space-navbar: 4em;

  --font-heading: 'Calibre', 'Helvetica Neue', helvetica, 'Apple Color Emoji', arial, sans-serif;
  --font-sans: 'Source Sans Pro', 'Helvetica Neue', helvetica, 'Apple Color Emoji', arial, sans-serif;
  --font-mono: 'Courier Prime', monospace;
}

html  {
  font-size: 10px;
  font-weight: 300;
  scroll-behavior: smooth;
}

body {
  border-top: 10px solid var(--color-purple-md);
  color: var(--color-text);
  background: var(--color-background);
}

a {
  color: var(--link-color);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

mark {
  border-radius: 1.4em 0 2em 0;
  background: transparent;
  background-image: linear-gradient(-100deg, hsla(48, 94%, 68%, 0.3), hsla(48, 94%, 68%, 0.7) 95%, hsla(48, 94%, 68%, 0.1));
}

header {
  padding-top: var(--space-medium);
  padding-bottom: var(--space-medium);
  padding-left: var(--space-xxx-large);
  padding-right: var(--space-xxx-large);
  box-shadow: 0px 30px 30px var(--color-purple-tint);
  margin-bottom: 4em;
  background: var(--color-purple-tint);

  & > ui-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(15em, 1fr));
    grid-auto-flow: row;
    justify-items: center;
    gap: var(--space-x-large);
    align-items: center;
  }

  & #book-cover {
    width: 16em;
    height: auto;
    box-shadow: 0px 10px 30px var(--color-gray-m);
    border: 1.5px solid var(--color-indigo-d);
    border-radius: 10px;
    transform: perspective(600px) rotateY(18deg);
    transition: 0.25s ease-in-out;
  }

  & #book-cover:hover {
    transform: scale(1.1) !important;
  }

  & h1 {
    margin-bottom: var(--space-medium);

    & a {
      text-decoration: underline;
      text-decoration-color: var(--color-yellow-m);
      text-decoration-thickness: 0.08em;
      text-underline-offset: 10px;
      color: inherit;
      transition: 0.1s ease-in-out;
    }

    & a:hover {
      text-decoration: underline;
      text-decoration-thickness: 0.08em;
      text-underline-offset: 16px;
    }

    & a.rails:hover {
      text-decoration-color: #D30001;
    }

    & a.hotwire:hover {
      text-decoration-color: #5cd8e5;
    }
  }

  & p {
    font-weight: 400;
    font-size: 105%;
  }

  & p.large {
    font-weight: 600;
    font-size: var(--type-large);
  }

  & p.mailing-list {
    margin-top: var(--space-medium);
    text-align: center;
  }

  & .not-by-ai {
    margin-top: var(--space-large);

    & img {
      width: 15ch !important;
    }
  }
}

@media (max-width: 60em) {
  header {
    padding-left: var(--space-large);
    padding-right: var(--space-large);
  }
}

section.learnings {
  max-width: 40em;
  margin: auto;
  padding-right: var(--space-medium);
  padding-left: var(--space-medium);

  & dl {
    margin-top: var(--space-medium);

    & div {
      margin-bottom: var(--space-large);
    }

    & dt {
      margin-bottom: var(--space-small);
      font-size: var(--type-large);
      font-weight: 600;
    }

    & dd {
      font-size: var(--type-medium);
    }
  }
}

section.toc {
  padding: var(--space-large) var(--space-medium);
  margin: auto var(--space-medium);
  background: var(--color-purple-tint);
  border-radius: 20px;
  border: 2px solid var(--color-indigo-d);

  & article {
    text-align: center;

    & details summary {
      display: inline-block;
      width: auto;
      list-style: none;
      margin-top: var(--space-medium);
      padding: var(--space-x-small) var(--space-medium);
      text-decoration: none;
      border-radius: 0.5em;
      box-shadow: 3px 3px 10px var(--color-gray-ml);
      box-sizing: border-box;
      border-color: var(--button-color);
      border: 2px solid var(--button-color);
      font-size: var(--type-large) !important;
      font: var(--type-sans);
      cursor: pointer;
      font-weight: 600;
      user-select: none;

      & span.show-when-closed {
        display: initial;
      }

      & span.show-when-open {
        display: none;
      }
    }

    & details[open] summary {
      background: var(--button-color);
      color: white;

      & span.show-when-closed {
        display: none;
      }

      & span.show-when-open {
        display: initial;
      }
    }

    & details > summary::-webkit-details-marker,
    & details > summary::marker {
      display: none;
    }
  }

  & dl {
    margin: auto;
    margin-top: var(--space-large);
    margin-bottom: var(--space-large);
    max-width: 60em;
    text-align: left;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20em, 1fr));
    grid-auto-flow: row;
    gap: var(--space-large);

    & div {
      background: var(--color-background);
      padding: var(--space-medium);
      border-radius: 10px;
    }

    & dt {
      margin-bottom: var(--space-xx-small);
      font-size: var(--type-large);
      font-weight: 600;
    }

    & dd {
      font-size: var(--type-medium);
    }
  }
}

section.whats-included {
  max-width: 60em;
  margin: auto;
  margin-bottom: var(--space-large);
  padding-right: var(--space-medium);
  padding-left: var(--space-medium);

  & dl {
    margin-top: var(--space-medium);

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20em, 1fr));
    grid-auto-flow: row;
    gap: var(--space-large);

    & div {
      padding: var(--space-medium);
      background: var(--color-gray-tint);
      border-radius: 10px;
      position: relative;
    }

    & dt {
      font-weight: bold;

      & .icon {
        position: absolute;
        right: var(--space-medium);
        top: var(--space-small);
      }
    }
  }
}

section.mailing-list {
  max-width: 60em;
  margin: auto;
  margin-bottom: var(--space-large);
  padding-right: var(--space-medium);
  padding-left: var(--space-medium);

  & > icon-container {
    display: block;
    margin-bottom: 0;
    margin-top: var(--space-medium);
    text-align: center;

    & svg.down-arrow {
      height: 4em;
      fill: var(--color-purple-md);
    }
  }

  & p.free-chapters {
    text-align: center;
    max-width: 25em;
    margin: auto;
    margin-top: var(--space-medium);
    font-size: var(--type-large);
    font-weight: 600;
    background: var(--color-yellow-tint);
    padding: var(--space-medium);
    border-radius: 20px;
    transition: 0.25s ease-in-out;

    & a {
      color: unset;
    }

    & a:hover {
      text-decoration: unset;
    }

    & icon-container {
      display: block;
      margin-bottom: 0;
      margin-top: var(--space-medium);
    }

    & svg.download {
      height: 2em;
      fill: var(--color-yellow-d);
    }
  }

  & p.free-chapters:hover {
    transform: scale(1.05) translateY(-0.5em);
  }

  & ui-container.form {
    max-width: 60em;
    margin: auto;
    display: flex;
    flex-wrap: wrap-reverse;
    align-items: center;
    justify-content: center;
    margin-top: var(--space-medium);

    & article {
      font-size: var(--type-large);
      max-width: 25em;
      padding: var(--space-large);

      & p {
        font-weight: 500;
      }
    }

    & form {
      padding: var(--space-large);
    }
  }
}

@media (max-width: 60em) {
  section.mailing-list {
    & ui-container.form {
      margin-top: 0;

      & article {
        padding: 0;
      }
    }
  }
}

section.blog {
  max-width: 60em;
  margin: auto;
  margin-bottom: var(--space-large);
  padding-right: var(--space-medium);
  padding-left: var(--space-medium);

  & p {
    margin-top: 0;
    font-weight: 500;
    font-style: italic;
  }

  & ul {
    margin-top: var(--space-medium);
    margin-left: var(--space-large);
    list-style-type: disc;

    & li {
      padding-left: var(--space-small);
      font-family: var(--font-mono);
      font-size: var(--type-large);

      & svg.icon {
        width: 1em;
        transform: translateY(6px);
        fill: var(--color-text);
      }
    }
  }
}

section.faq {
  max-width: 60em;
  margin: auto;
  margin-bottom: var(--space-large);
  padding-right: var(--space-medium);
  padding-left: var(--space-medium);

  & dl {
    margin: auto;
    margin-top: var(--space-medium);
    max-width: 50em;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20em, 1fr));
    grid-auto-flow: row;
    gap: var(--space-large);

    & div {
      background: var(--color-gray-tint);
      padding: var(--space-medium);
      border-radius: 10px;
    }

    & dt {
      margin-bottom: var(--space-xx-small);
      font-size: var(--type-large);
      font-weight: 600;
    }

    & dd {
      font-size: var(--type-medium);
    }
  }
}

section.about {
  margin-left: var(--space-medium);
  margin-right: var(--space-medium);

  & ui-container {
    display: block;
    max-width: 40em;
    margin: auto;
    margin-bottom: var(--space-large);
    border: 2px solid var(--color-indigo-d);
    padding: var(--space-medium);
    border-radius: 20px;
  }

  & h2 {
    text-align: center;
  }

  & div {
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  & article {
    max-width: 25em;
  }

  & figure {
    margin: auto;
  }

  & img {
    border-radius: 50%;
    border: 2px solid var(--color-purple-d);
    height: 10em;
  }

  & p.social {
    text-align: center;

    & svg {
      height: 1.5em;
      fill: var(--button-color);
    }

    & svg:hover {
      fill: var(--button-hover-color);
    }

    & a:hover {
      text-decoration: none;
    }
  }
}

section.testimonials {
  display: flex;
  overflow: scroll;
  align-items: stretch;

  margin-top: var(--space-large);
  margin-left: var(--space-medium);
  margin-right: var(--space-medium);
  margin-bottom: var(--space-large);

  & article {
    flex: 1 0 17em;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: var(--color-indigo-tint);
    border-radius: 10px;
    padding: var(--space-medium);
    margin: var(--space-medium);
    max-width: 25em;
  }

  & ui-spacer {
    display: block;
    flex: 1 1 auto;
  }

  & blockquote {
    font-weight: 400;

    &:before {
      content: '❝ ';
    }

    &:after {
      content: ' ❞';
    }
  }

  & figure {
    margin-top: var(--space-x-small);
    justify-content: flex-end;
    display: flex;
    align-items: center;

    & img {
      height: 3em;
      width: auto;
      border-radius: 50%;
    }

    & figcaption {
      display: flex !important;
      flex-direction: row;
      font-weight: 500;
      margin-right: var(--space-small);

      & profile-container {
        display: flex;
        flex-direction: column;

        & span {
          margin-top: -0.2em;

          & p {
            font-size: var(--type-x-small);
            font-weight: 400;
          }
        }
      }
    }
  }
}


img.icon {
  height: 2em;
  width: auto;
}

svg.icon {
  width: auto;
}

footer {
  display: flex;
  background: var(--color-gray-tint);
  font-size: var(--type-x-small);
  text-align: right;
  padding: 0 var(--space-medium);
  flex-wrap: wrap;

  & span {
    flex: 0 0 auto;
  }

  & span.icons {
    flex: 1 0 auto;
  }
}

section.buy {
  max-width: 20em;
  margin: auto;
  margin-top: var(--space-medium);
  margin-bottom: var(--space-large);
}

figure.not-by-ai {
  text-align: center;

  & img {
    height: auto;
    width: 20ch;
  }
}

dismissable-banner {
  display: block;
  position: relative;
  background: var(--color-teal-d);
  border-radius: 0.3em;
  margin: auto;
  padding: var(--space-small) var(--space-medium);
  padding-right: var(--space-x-large);

  max-width: 40em;
  color: white;
  text-align: center;
  font-size: var(--type-medium);
  font-weight: 500;

  & button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    appearance: none;
    cursor: pointer;
    position: absolute;
    right: 1em;
    top: 50%;
    transform: translateY(-50%);

    & svg {
      height: 1.5em;
      width: 1.5em;
      fill: white;
    }
  }

  & a {
    color: white;
  }
}