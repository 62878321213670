@font-face {
    font-family: 'Source Sans Pro';
    src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'),
        url("../fonts/source-sans-pro/SourceSansPro-Bold.woff2") format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: local('Source Sans Pro Black Italic'), local('SourceSansPro-BlackItalic'),
        url("../fonts/source-sans-pro/SourceSansPro-BlackItalic.woff2") format('woff2');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: local('Source Sans Pro Black'), local('SourceSansPro-Black'),
        url("../fonts/source-sans-pro/SourceSansPro-Black.woff2") format('woff2');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: local('Source Sans Pro ExtraLight'), local('SourceSansPro-ExtraLight'),
        url("../fonts/source-sans-pro/SourceSansPro-ExtraLight.woff2") format('woff2');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: local('Source Sans Pro Bold Italic'), local('SourceSansPro-BoldItalic'),
        url("../fonts/source-sans-pro/SourceSansPro-BoldItalic.woff2") format('woff2');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: local('Source Sans Pro Italic'), local('SourceSansPro-Italic'),
        url("../fonts/source-sans-pro/SourceSansPro-Italic.woff2") format('woff2');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: local('Source Sans Pro ExtraLight Italic'), local('SourceSansPro-ExtraLightItalic'),
        url("../fonts/source-sans-pro/SourceSansPro-ExtraLightItalic.woff2") format('woff2');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: local('Source Sans Pro Light'), local('SourceSansPro-Light'),
        url("../fonts/source-sans-pro/SourceSansPro-Light.woff2") format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'),
        url("../fonts/source-sans-pro/SourceSansPro-Regular.woff2") format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: local('Source Sans Pro Light Italic'), local('SourceSansPro-LightItalic'),
        url("../fonts/source-sans-pro/SourceSansPro-LightItalic.woff2") format('woff2');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: local('Source Sans Pro SemiBold Italic'), local('SourceSansPro-SemiBoldItalic'),
        url("../fonts/source-sans-pro/SourceSansPro-SemiBoldItalic.woff2") format('woff2');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans Pro';
    src: local('Source Sans Pro SemiBold'), local('SourceSansPro-SemiBold'),
        url("../fonts/source-sans-pro/SourceSansPro-SemiBold.woff2") format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

