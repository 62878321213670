:root {
  --type-base: calc(1.4em + 0.5vw);
  --type-xxx-small: 55%;
  --type-xx-small: 65%;
  --type-x-small: 75%;
  --type-small: 85%;
  --type-medium: 110%;
  --type-large: 125%;
  --type-x-large: 150%;
  --type-xx-large: 200%;
  --type-xxx-large: 250%;
}

body {
  font-size: var(--type-base);
  font-family: var(--font-sans);
  line-height: 1.6;
}

h1 {
  font-family: var(--font-heading);
  font-size: var(--type-xxx-large);
  color: var(--color-indigo-d);
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: 0.01em;
}

h2 {
  font-family: var(--font-heading);
  font-size: var(--type-xx-large);
  color: var(--color-indigo-md);
  line-height: 1.3;
  font-weight: 600;
  letter-spacing: 0.02em;
}

h3 {
  font-size: var(--type-x-large);
  color: var(--color-indigo-md);
  line-height: 1.3;
  font-weight: 600;
}

h4 {
  font-size: var(--type-large);
  line-height: 1.3;
}

h5 {
  font-size: var(--type-medium);
  font-weight: 800;
  line-height: 1.3;
}

h6 {
  font-size: var(--type-small);
  font-weight: 900;
  line-height: 1.3;
}

@media (max-width: 60em) {
  h1 {
    font-size: var(--type-xx-large);
  }

  h2 {
    font-size: var(--type-x-large);
  }

  h3 {
    font-size: var(--type-large);
  }

  h4 {
    font-size: var(--type-medium);
  }
}

code {
  font-family: var(--font-mono);
}

p {
  font-weight: 300;
}

p + p {
  margin: var(--space-medium) 0;
}

em {
  font-style: normal;
  color: var(--color-purple-md);
  font-weight: 700;
}

span.strike {
  text-decoration: line-through;
  text-decoration-thickness: 2px;
}