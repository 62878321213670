@font-face {
    font-family: 'Calibre';
    src: url("../fonts/calibre/Calibre-Light.eot");
    src: local('Calibre Light'), local('Calibre-Light'),
        url("../fonts/calibre/Calibre-Light.eot?#iefix") format('embedded-opentype'),
        url("../fonts/calibre/Calibre-Light.woff2") format('woff2'),
        url("../fonts/calibre/Calibre-Light.woff") format('woff'),
        url("../fonts/calibre/Calibre-Light.ttf") format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Calibre';
    src: url("../fonts/calibre/Calibre-Thin.eot");
    src: local('Calibre Thin'), local('Calibre-Thin'),
        url("../fonts/calibre/Calibre-Thin.eot?#iefix") format('embedded-opentype'),
        url("../fonts/calibre/Calibre-Thin.woff2") format('woff2'),
        url("../fonts/calibre/Calibre-Thin.woff") format('woff'),
        url("../fonts/calibre/Calibre-Thin.ttf") format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Calibre';
    src: url("../fonts/calibre/Calibre-LightItalic.eot");
    src: local('Calibre Light Italic'), local('Calibre-LightItalic'),
        url("../fonts/calibre/Calibre-LightItalic.eot?#iefix") format('embedded-opentype'),
        url("../fonts/calibre/Calibre-LightItalic.woff2") format('woff2'),
        url("../fonts/calibre/Calibre-LightItalic.woff") format('woff'),
        url("../fonts/calibre/Calibre-LightItalic.ttf") format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Calibre';
    src: url("../fonts/calibre/Calibre-MediumItalic.eot");
    src: local('Calibre Medium Italic'), local('Calibre-MediumItalic'),
        url("../fonts/calibre/Calibre-MediumItalic.eot?#iefix") format('embedded-opentype'),
        url("../fonts/calibre/Calibre-MediumItalic.woff2") format('woff2'),
        url("../fonts/calibre/Calibre-MediumItalic.woff") format('woff'),
        url("../fonts/calibre/Calibre-MediumItalic.ttf") format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Calibre';
    src: url("../fonts/calibre/Calibre-BlackItalic.eot");
    src: local('Calibre Black Italic'), local('Calibre-BlackItalic'),
        url("../fonts/calibre/Calibre-BlackItalic.eot?#iefix") format('embedded-opentype'),
        url("../fonts/calibre/Calibre-BlackItalic.woff2") format('woff2'),
        url("../fonts/calibre/Calibre-BlackItalic.woff") format('woff'),
        url("../fonts/calibre/Calibre-BlackItalic.ttf") format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Calibre';
    src: url("../fonts/calibre/Calibre-Semibold.eot");
    src: local('Calibre Semibold'), local('Calibre-Semibold'),
        url("../fonts/calibre/Calibre-Semibold.eot?#iefix") format('embedded-opentype'),
        url("../fonts/calibre/Calibre-Semibold.woff2") format('woff2'),
        url("../fonts/calibre/Calibre-Semibold.woff") format('woff'),
        url("../fonts/calibre/Calibre-Semibold.ttf") format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Calibre';
    src: url("../fonts/calibre/Calibre-Black.eot");
    src: local('Calibre Black'), local('Calibre-Black'),
        url("../fonts/calibre/Calibre-Black.eot?#iefix") format('embedded-opentype'),
        url("../fonts/calibre/Calibre-Black.woff2") format('woff2'),
        url("../fonts/calibre/Calibre-Black.woff") format('woff'),
        url("../fonts/calibre/Calibre-Black.ttf") format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Calibre';
    src: url("../fonts/calibre/Calibre-Medium.eot");
    src: local('Calibre Medium'), local('Calibre-Medium'),
        url("../fonts/calibre/Calibre-Medium.eot?#iefix") format('embedded-opentype'),
        url("../fonts/calibre/Calibre-Medium.woff2") format('woff2'),
        url("../fonts/calibre/Calibre-Medium.woff") format('woff'),
        url("../fonts/calibre/Calibre-Medium.ttf") format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Calibre';
    src: url("../fonts/calibre/Calibre-RegularItalic.eot");
    src: local('Calibre Regular Italic'), local('Calibre-RegularItalic'),
        url("../fonts/calibre/Calibre-RegularItalic.eot?#iefix") format('embedded-opentype'),
        url("../fonts/calibre/Calibre-RegularItalic.woff2") format('woff2'),
        url("../fonts/calibre/Calibre-RegularItalic.woff") format('woff'),
        url("../fonts/calibre/Calibre-RegularItalic.ttf") format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Calibre';
    src: url("../fonts/calibre/Calibre-BoldItalic.eot");
    src: local('Calibre Bold Italic'), local('Calibre-BoldItalic'),
        url("../fonts/calibre/Calibre-BoldItalic.eot?#iefix") format('embedded-opentype'),
        url("../fonts/calibre/Calibre-BoldItalic.woff2") format('woff2'),
        url("../fonts/calibre/Calibre-BoldItalic.woff") format('woff'),
        url("../fonts/calibre/Calibre-BoldItalic.ttf") format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Calibre';
    src: url("../fonts/calibre/Calibre-Bold.eot");
    src: local('Calibre Bold'), local('Calibre-Bold'),
        url("../fonts/calibre/Calibre-Bold.eot?#iefix") format('embedded-opentype'),
        url("../fonts/calibre/Calibre-Bold.woff2") format('woff2'),
        url("../fonts/calibre/Calibre-Bold.woff") format('woff'),
        url("../fonts/calibre/Calibre-Bold.ttf") format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Calibre';
    src: url("../fonts/calibre/Calibre-Regular.eot");
    src: local('Calibre'), local('Calibre-Regular'),
        url("../fonts/calibre/Calibre-Regular.eot?#iefix") format('embedded-opentype'),
        url("../fonts/calibre/Calibre-Regular.woff2") format('woff2'),
        url("../fonts/calibre/Calibre-Regular.woff") format('woff'),
        url("../fonts/calibre/Calibre-Regular.ttf") format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Calibre';
    src: url("../fonts/calibre/Calibre-SemiboldItalic.eot");
    src: local('Calibre Semibold Italic'), local('Calibre-SemiboldItalic'),
        url("../fonts/calibre/Calibre-SemiboldItalic.eot?#iefix") format('embedded-opentype'),
        url("../fonts/calibre/Calibre-SemiboldItalic.woff2") format('woff2'),
        url("../fonts/calibre/Calibre-SemiboldItalic.woff") format('woff'),
        url("../fonts/calibre/Calibre-SemiboldItalic.ttf") format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Calibre';
    src: url("../fonts/calibre/Calibre-ThinItalic.eot");
    src: local('Calibre Thin Italic'), local('Calibre-ThinItalic'),
        url("../fonts/calibre/Calibre-ThinItalic.eot?#iefix") format('embedded-opentype'),
        url("../fonts/calibre/Calibre-ThinItalic.woff2") format('woff2'),
        url("../fonts/calibre/Calibre-ThinItalic.woff") format('woff'),
        url("../fonts/calibre/Calibre-ThinItalic.ttf") format('truetype');
    font-weight: 100;
    font-style: italic;
}

