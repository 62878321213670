form {
  display: flex;
  flex-direction: column;
  max-width: 15em;
  align-items: center;
  width: unset;

  background: var(--color-indigo-tint);
  padding: var(--space-medium);
  padding-bottom: var(--space-large);
  border-radius: 10px;
}

input[type='text'],
input[type='email'] {
  font-size: var(--type-medium);
  border-radius: 0.6em;
  border: solid 2px var(--color-text);
  padding: var(--space-small);
  outline: none;
  max-width: 100%;

  &::placeholder {
    font-size: var(--type-small);
    font-family: var(--font-sans);
  }

  &:focus {
    border-color: var(--color-yellow-m);
  }
}

text-field-container {
  display: flex;
  flex-direction: column-reverse;
  font-size: var(--type-small);

  & label {
    line-height: 1.8;
    font-weight: 400;
    font-size: var(--type-large);
    margin-left: 0.6em;
  }
}

text-field-container + text-field-container {
  margin-top: 1em;
}

input[type='submit'], button:not(.vanilla), a.button {
  display: block;
  padding: var(--space-x-small) var(--space-medium);
  text-decoration: none;
  color: white;
  border-radius: 0.5em;
  box-shadow: 3px 3px 10px var(--color-gray-ml);
  transition: transform 0.1s linear;
  box-sizing: border-box;
  background: var(--button-color);
  border: 2px solid var(--button-color);
  font-size: var(--type-large) !important;
  margin-top: var(--space-large);
  align-self: center;
  font: var(--type-sans);
  cursor: pointer;
  font-weight: 600;

  &:hover {
    transform: translate(2px, 2px);
    background: var(--button-hover-color);
    border-color: var(--button-hover-color);
  }
}

a.button {
  text-align: center;
}

a.button:hover {
  text-decoration: none;
}