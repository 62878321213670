@font-face {
    font-family: 'Courier Prime';
    src: local('Courier Prime Regular'), local('CourierPrime-Regular'),
        url("../fonts/courier-prime/CourierPrime-Regular.woff2") format('woff2'),
        url("../fonts/courier-prime/CourierPrime-Regular.woff") format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Courier Prime';
    src: local('Courier Prime Bold'), local('CourierPrime-Bold'),
        url("../fonts/courier-prime/CourierPrime-Bold.woff2") format('woff2'),
        url("../fonts/courier-prime/CourierPrime-Bold.woff") format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Courier Prime';
    src: local('Courier Prime Italic'), local('CourierPrime-Italic'),
        url("../fonts/courier-prime/CourierPrime-Italic.woff2") format('woff2'),
        url("../fonts/courier-prime/CourierPrime-Italic.woff") format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Courier Prime';
    src: local('Courier Prime Bold Italic'), local('CourierPrime-BoldItalic'),
        url("../fonts/courier-prime/CourierPrime-BoldItalic.woff2") format('woff2'),
        url("../fonts/courier-prime/CourierPrime-BoldItalic.woff") format('woff');
    font-weight: bold;
    font-style: italic;
}

